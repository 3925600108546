<template>
  <b-card style="padding: 0rem">
    <b-card-body style="padding: 0rem">
      <div class="">
        <!-- filter -->
        <div class="mb-4">
          <h3>Feuille de temps</h3>
          <span class="text-muted h5">{{
            userInfo.firstname + " " + userInfo.lastname
          }}</span>
        </div>
        <div>
          <div class="row ml-1 mb-2">
            <p>Légende :</p>
            <div class="">
              <span
                class="fs-6 text-center alert-success"
                style="font-size: 90%; padding: 1vh; border-radius: 10px"
                >Nombre d'heures supérieur à 8</span
              >
            </div>
            <div class="ml-1 xl-top-2">
              <span
                class="fs-6 text-center alert-danger"
                style="font-size: 90%; padding: 1vh; border-radius: 10px"
                >Nombre d'heures inférieur à 8</span
              >
            </div>
            <div class="ml-1">
              <span
                class="fs-6 text-center alert-secondary"
                style="font-size: 90%; padding: 1vh; border-radius: 10px"
                >Journée incomplète</span
              >
            </div>
            <b-form-group
              label=""
              label-cols-sm="0"
              label-align-sm="left"
              label-size=""
              label-for="filterInput"
              class="mb-2 ml-auto mr-1"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Rechercher..."
                />
              </b-input-group>
            </b-form-group>
          </div>
        </div>
      </div>
    </b-card-body>

    <b-table
      no-border-collapse
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="timesheets"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
      :tbody-tr-class="rowClass"
    >
      <template #cell(id)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(created_at)="data">
        {{ getDate(data.item.created_at) }}
      </template>

      <!-- <template #cell(arrival)="data">
        {{ adjustTime(data.item.arrival) }}
      </template> -->

      <template #cell(start_pause)="data">
        {{ data.item.arrival ? data.item.start_pause : 'Absent' }}
      </template>
      <!-- <template #cell(end_pause)="data">
        {{ adjustTime(data.item.end_pause) }}
      </template>
      <template #cell(departure)="data">
        {{ adjustTime(data.item.departure) }} 
      </template> -->
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Affichage par page"
        label-cols="8"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { BProgress } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import moment from "moment";
import {
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  BCard,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BProgress,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },

      fields: [
        { key: "id", label: "#", sortable: true },
        { key: "created_at", label: "Dates", sortable: true },

        { key: "arrival", label: "Arrivée" },
        ,
        { key: "start_pause", label: "Début pause" },
        ,
        { key: "end_pause", label: "Fin pause" },
        ,
        { key: "departure", label: "Départ" },
        { key: "total", label: "Total" },
      ],
      /* eslint-disable global-require */
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },

    ...mapState("auth", ["timesheets", "userInfo"]),
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.timesheets.length;

    /*  */

    this.getTimesheets();
  },
  methods: {
    adjustTime(time) {
      if (time) {
        var date_system = new Date();
        var date_time = new Date("08/05/2015 " + time);

        const timezone = -date_system.getUTCHours() + date_system.getHours();
        date_time.setHours(date_time.getHours() + timezone);
        var options = { hour: "2-digit", minute: "2-digit", second: "2-digit" };

        return date_time.toLocaleTimeString("fr-FR", options);
      } else {
        return "";
      }
    },
    ...mapActions("auth", ["getTimesheets"]),

    getUser(data) {
      return data.user?.firstname + " " + data.user?.lastname;
    },

    getDate(date) {
      return moment(date).locale("fr").format("DD MMMM YYYY");
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    rowClass(item, type) {
      const supColor = "table-success text-success";
      const infColor = "table-danger text-danger";
      const incColor = "table-light ";

      if (item.total == null) {
        return;
      }
      var total = item.total.split(":")[0];
      // eslint-disable-next-line consistent-return
      if (total >= 8) {
        return supColor;
      }
      if (total < 8) {
        return infColor;
      }
    },
  },
};
</script>
